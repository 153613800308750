import { React, useState, useEffect, useRef } from 'react';
import { Box, Typography, Tabs, Dialog, DialogActions, DialogContent, 
    DialogTitle, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { HelpOutlineOutlined, Cancel, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { AntTab } from '../../../components/CustomStyles';
import SummaryTable from './SummaryTable';
import SummaryByEntityTable from './SummaryByEntityTable';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import dayjs from 'dayjs';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useProcesses } from '../../../hooks/useProcesses';
import { useProcessFilters } from '../../../hooks/useProcessFilters';
import { useUsers } from '../../../hooks/useUsers';
import SettingsMenu from '../SettingsMenu';
import { lastExecutionService } from '../../../services/lastExecutionService';
import { useErrors } from '../../../hooks/useErrors';
import { processesService } from '../../../services/processesService';


const menuOptions = [
    {
        'tabName': 'Resumen',
        'apiService': 'summary',
    },
    {
        'tabName': 'Resumen por entidad',
        'apiService': 'summary-by-entity',
    }
]

const normativeVersionsList = [
    'CRR2',
    'CRR3'
];



export default function CreditRiskReport() {
    const { isBelowXlScreen } = useResponsive();
    // API Services
    const { getExcelFile } = reportsCreditRiskService();
    const { getLastExecution } = lastExecutionService();
    const { getProcesses } = processesService();
    // Custom Hooks
    const { usersList } = useUsers();
    const { filters, fullDates, handleOnChangeFilter, areAnyFilters } = useProcessFilters({ defaultProcessType: "Riesgo de Crédito y Contraparte", defaultState: "Completado", defaultIncludeComments: "False", defaultIncludeFiles: "False" });
    // States
    const [selectedExecution, setSelectedExecution] = useState("");
    const [executionsList, setExecutionsList] = useState([]);
    const [executionDateIdDict, setExecutionDateIdDict] = useState({});
    const [executionsTooltips, setExecutionsTooltips] = useState({});
    const [executionReportData, setExecutionReportData] = useState({});
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // Refs
    const isFirstRender = useRef(true);

    useEffect(() => {
        fetchInitialProcessData();
    }, []);

    useEffect(() => {
        if (areAnyFilters(["includeComments", "includeFiles", "state", "processType"])) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            }
            else {
                fetchFilteredExecutions();
            }
        }
        
    }, [filters]);

    const fetchInitialProcessData = async () => {
        try {
            // Buscar el último proceso de Riesgo de Crédito y Contraparte ejecutado con estado Completado para mostrarlo cuando se inicia el componente
            const lastExecutions = (await getLastExecution({ ...filters, screen_page: "reports", report_structure: menuOptions[tab].apiService }))
            if (Object.keys(lastExecutions).length !== 0) {
                handleOnChangeFilter("userName", [lastExecutions.last_process.user]);
                handleOnChangeFilter("normativeVersion", lastExecutions.last_process.version);
                handleOnChangeFilter("DataStart", dayjs(lastExecutions.last_process.data_date + "-01"));
                handleOnChangeFilter("ExecutionStart", dayjs(lastExecutions.last_process.execution_year + "-" + lastExecutions.last_process.execution_month + "-01"));
                setRelatedExecutionsData(lastExecutions.related_processes);
                setSelectedExecution(lastExecutions.last_process.execution_date.split('.')[0]);
                setExecutionReportData(lastExecutions.last_process.report_content)
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const fetchFilteredExecutions = async () => {
        try {
            const executions = (await getProcesses({filters: filters, limit: 100, start: 0})).results;
            if (executions && executions.length > 0) {
                setRelatedExecutionsData(executions);
            }
            else {
                setExecutionsList([]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const setRelatedExecutionsData = (related_processes) => {
        setExecutionsList(related_processes.map(execution => execution.execution_date.split('.')[0]));
        setExecutionDateIdDict(related_processes.reduce((acc, item) => {
            acc[item.execution_date.split('.')[0]] = item.process_id;
            return acc;
        }, {}))
        setExecutionsTooltips(related_processes.reduce((acc, item) => {
            const executionDate = item.execution_date.split('.')[0];
            const formattedString = `
                {bold}ID:{bold} ${item.process_id}{break}
                {bold}Fecha de datos:{bold} ${item.data_date}{break}
                {bold}Fecha de ejecución:{bold} ${item.execution_year}-${item.execution_month}-${item.execution_date.split('-')[2]}{break}
                {bold}Hora de ejecución:{bold} ${item.execution_date.split('-')[3].split('.')[0]}{break}
                {bold}Versión normativa:{bold} ${item.version}{break}
                {bold}Usuario:{bold} ${item.user}
            `.replace(/\s+/g, ' '); // Remover espacios innecesarios
        
            acc[executionDate] = formattedString;
            return acc;
        }, {}))
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const handleOnChangeSelectedExecution = (value) => {
        setSelectedExecution(value);
    };

    const changeFilter = (name, value) => { 
        setSelectedExecution("");
        setExecutionsList([]);
        handleOnChangeFilter(name, value);
    };


    return (
        <>
            <Box
                sx={{...CONTENT_BOX, display: 'flex', flexDirection: 'column', gap: 3 }}
            >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} >
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            Riesgo de Crédito y Contraparte
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth='lg'
                            fullWidth
                        >
                            <DialogTitle
                                color={BLUE}
                                sx={TITLE_TEXT}
                            >
                                Riesgo de Crédito y Contraparte
                            </DialogTitle>
                            <DialogContent >
                                {"Fichero donde se incluye el detalle de Riesgo de Crédito tanto a nivel grupal como granular, en concreto:"}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen:"}</u> {" Resultados agrupados por epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen entidad:"}</u> {" Resultados agrupados por entidad, epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Renta variable, Renta fija, Derivados, Inversión crediticia, Resto:"}</u> {" Resultados desglosados a nivel de contrato y cuenta contable, donde además de exposición, cobertura y APRs se pueden consultar atributos a nivel de operación para obtener una justificación de la asignación de categoría y ponderación obtenida."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Diferencias:"}</u> {" Contratos con categorías y/o ponderaciones distintas entre este mes y el anterior."}{' '}<br />
                            </DialogContent>
                            <DialogActions
                                sx={{ mb: '20px', justifyContent: 'center' }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<Cancel />}
                                    onClick={handleCloseDialog}
                                >
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog >
                    </Box>
                    <Button
                        variant='contained'
                        endIcon={<FileDownloadOutlined />}
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        onClick={() => getExcelFile({ year: filters.yearExecutionStart, month: filters.monthExecutionStart, process_id: executionDateIdDict[selectedExecution] })}
                        download={true}
                    >
                        Exportar Excel
                    </Button>
                </Box>
                <SettingsMenu
                    filters={filters}
                    fullDates={fullDates}
                    usersList={usersList}
                    selectedExecution={selectedExecution}
                    executionsList={executionsList}
                    executionsTooltips={executionsTooltips}
                    changeFilter={changeFilter}
                    handleOnChangeSelectedExecution={handleOnChangeSelectedExecution}
                    normativeVersionsList={normativeVersionsList}
                />
            </Box>
            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs 
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                        <AntTab 
                            tab={0}
                            label="Resumen" 
                        />
                        <AntTab 
                            tab={1}
                            label="Resumen por entidad" 
                        />
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab].tabName}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(0)}
                                    sx={{ color: tab === 0 ? BLUE : BLACK }}
                                >
                                    Resumen
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(1)}
                                    sx={{ color: tab === 1 ? BLUE : BLACK }}
                                >
                                    Resumen por entidad
                                </MenuItem>
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <SummaryTable
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={executionDateIdDict[selectedExecution]}
                            initialData={executionReportData}
                            isFirstRender={isFirstRender}
                        />
                    )}
                    {tab === 1 && (
                        <SummaryByEntityTable
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={executionDateIdDict[selectedExecution]}
                            initialData={executionReportData}
                            isFirstRender={isFirstRender}
                        />
                    )}
                </Box>
        </>
    );
}