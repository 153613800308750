import { useContext } from 'react';
import { ProcessesContext } from '../context/ProcessesContext'
import { processesService } from "../services/processesService";
import { processSituationService } from "../services/processSituationService";
import { processStateService } from '../services/processStateService';
import { parametrizationService } from '../services/parametrizationService';
import useDateParser from './useDateParser';

export const useProcesses = () => {
    const { processesList, setProcessesList, statesList, setStatesList, paginationData, setPaginationData, appliedFilters, setAppliedFilters } = useContext(ProcessesContext)
    const { getProcesses } = processesService();
    const { getParametrization } = parametrizationService();
    const { putProcessSituation } = processSituationService();
    const { getProcessState } = processStateService();


    const clearVariables = () => {
        setProcessesList([]);
        setStatesList([]);
        setPaginationData({start: 0, limit: 10, count: 0, page: 1});
        setAppliedFilters({});
    }
    

    const filterProcesses = async ({filters, limit}) => {
        const modFilters = {...filters};
        setAppliedFilters(modFilters);
        const data = await getProcesses({filters: modFilters, limit: limit ? limit : paginationData.limit, start: 0});
        if (data) {
            setProcessesList(data.results)
            setPaginationData({start: data.start, limit: data.limit, count: data.count, page: 1})
            return {processesList: data.results, paginationData: {start: data.start, limit: data.limit, count: data.count, page: 1}};
        }
    }

    const loadProcesses = async () => {
        const data = await getProcesses({filters: appliedFilters, limit: paginationData.limit, start: 0});
        if (data) {
            setProcessesList(data.results);
            setPaginationData({...paginationData, start: data.start, limit: data.limit, count: data.count});
        }
    };

    const loadStates = async () => {
        const data = await getParametrization({parameterCode: "states"});
        if (data) {
            setStatesList(data["states"]);
        }
    }

    const handleOnChangeSelectedPaginationLimit = async (event) => {
        const newPaginationLimit = event.target.value;
        if (newPaginationLimit <= paginationData.count) {
            const data = await getProcesses({filters: appliedFilters, limit: newPaginationLimit, start: 0});
            if (data) {
                setProcessesList(data.results);
                setPaginationData({start: data.start, limit: data.limit, count: data.count, page: 1});
            }
        }
    };

    const handleChangePage = async (event, value) => {
        const newPage = value;
        if (newPage !== paginationData.page) {
            const data = await getProcesses({filters: appliedFilters, limit: paginationData.limit, start: (newPage - 1) * paginationData.limit})
            if (data) {
                setProcessesList(data.results);
                setPaginationData({start: data.start, limit: data.limit, count: data.count, page: newPage});
            }
        }
    };

    const handleOnChangeSituation = (process_id, newValue) => {
        const newData = processesList.map((process) => {
            if (process_id === process.process_id) {
                return { ...process, situation: newValue };  
            }
            return process;
        });
        setProcessesList(newData);  
    };    

    const updateProcessSituation = async ({ situation, process_id }) => {
        await putProcessSituation({situation: situation, process_id: process_id});
    };

    const refreshProcessState = async ({process_id, year, month, current_state}) => {
        const data = await getProcessState({year: year, month: month, process_id: process_id});
        if (data && current_state !== data.state) {
            const newData = processesList.map((process) => {
                if (process_id === process.process_id) {
                    return { ...process, state: data.state };  
                }
                return process;
            });
            setProcessesList(newData);  
        }
    }

    return {
        processesList, 
        setProcessesList, 
        statesList,
        paginationData, 
        setPaginationData,
        appliedFilters,
        filterProcesses,
        loadProcesses,
        loadStates,
        handleOnChangeSelectedPaginationLimit,
        handleChangePage,
        handleOnChangeSituation,
        updateProcessSituation,
        refreshProcessState
    };
};