import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function lastExecutionService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const getLastExecution = async ({includeComments, normativeVersion, state, userName, processType, monthExecutionStart, yearExecutionStart, monthDataStart, yearDataStart, screen_page, report_structure}) => {
        const key = 'getLastExecution';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/latest/" + screen_page + "/" + report_structure);
        var queryParams = {includeComments, normativeVersion, state, userName, processType, monthExecutionStart, yearExecutionStart, monthDataStart, yearDataStart};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error encontrando la última ejecución.");
                Sentry.captureException(response, {
                    tags: { service: 'lastExecutionService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando la última ejecución.");
            Sentry.captureException(error, {
                tags: { service: 'lastExecutionService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { getLastExecution }
}