import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsMarketRiskService } from '../../../services/reportsMarketRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';

export default function RawMaterialsTable({executionYear, executionMonth, processId, initialData, isFirstRender}) {
    const { isBelowLgScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getMartketRiskReportRawMaterials } = reportsMarketRiskService();
    const formatNumber = useNumberFormatter();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        if (processId && !isFirstRender.current) {
            fetchReportData();
        }
    }, [processId]);

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) {
            setReportStates(initialData);
        }
    }, [initialData]);

    const fetchReportData = async () => {
        const response = await getMartketRiskReportRawMaterials({ year: executionYear, month: executionMonth, process_id: processId });
        if (response) {
            setReportStates(response);
        }
    };

    const setReportStates = (data) => {
        if (data.current_values) {
            const { parameters, current_values } = data;
            const sortedParams = Object.keys(parameters).sort((a, b) => parameters[a].column_order - parameters[b].column_order);
            setParameters(sortedParams.map(param => ({ key: param, name: parameters[param].name })));
            setReportData(current_values);
        }
    };

    if (loading.getMartketRiskReportRawMaterials || loading.getLastExecution) {
        return <LoadingSpinner />;
    } else if (inLineErrors.getMartketRiskReportRawMaterials) {
        return <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getMartketRiskReportRawMaterials} />;
    } else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowLgScreen ? 'auto' : 'hidden' }}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse', minWidth: '1250px', tableLayout: 'auto' }}>
                    <TableHead >
                        <InformesTableRow>
                            {parameters.map((param, index) => (
                                <InformesTableCell key={index} sx={{ textAlign: 'left', minWidth: `${param.name.length}ch`}}>
                                    {param.name}
                                </InformesTableCell>
                            ))}
                        </InformesTableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.length === 0 ? (
                            <InformesTableRow>
                                <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                    No hay datos para mostrar
                                </InformesTableCell>
                            </InformesTableRow>
                        ) : (
                            reportData.map((row, rowIndex) => (
                                <InformesTableRow key={rowIndex}>
                                    {parameters.map((param, idx) => (
                                        <InformesTableCell key={idx} sx={{ textAlign: 'left' }}>
                                            {param.type === "number" && row[param.key] !== undefined
                                                ? formatNumber(row[param.key]) // Si es número, aplicamos formatNumber
                                                : row[param.key] !== undefined
                                                ? row[param.key] // Si no es número, mostramos el valor tal cual
                                                : ""}
                                        </InformesTableCell>
                                    ))}
                                </InformesTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
