import { React, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive';
import RiskPonderationEADsTable from './tables/RiskPonderationEADsTable';
import CategoryIndicatorsDonutChart from './CategoryIndicatorsDonutChart';
import CategoriesIndicatorsBarChart from './CategoriesIndicatorsBarChart';




export default function TabCreditCounterpartyRisk({ data }) {    
    const { isBelowMdScreen } = useResponsive();    
    const [expandedSettings, setExpandedSettings] = useState(false);

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowMdScreen]);    

    

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <CategoriesIndicatorsBarChart data={data} />
                <Box sx={{ width: '100%', ml: '0px', display: 'flex', gap: '100px' }}>
                    <CategoryIndicatorsDonutChart data={data} indicator={"APRs"} />
                    <CategoryIndicatorsDonutChart data={data} indicator={"Exposición Original"} />
                </Box>
            </Box>
            <Box sx={{ mt: '20px', width: '100%', height: '700px' }}>
                <RiskPonderationEADsTable data={data} />
            </Box>
        </>
    );
}