import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function cdmCreditRiskService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError } = useErrors();

    const getCdMCreditRiskCategoriesPonderations = async ({year, month, process_id}) => {
        const key = 'getCdMCreditRiskCategoriesPonderations';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/cdm-executions/credit-risk/summary-categories-ponderations");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el resumen de categorias y ponderacion de riesgo de crédito.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsCreditRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el resumen de categorias y ponderacion de riesgo de crédito.");
            Sentry.captureException(error, {
                tags: { service: 'reportsCreditRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getCdMCreditRiskCategoriesPonderations };
}
