import React, { useState, useEffect, useRef } from 'react';
import DonutChart from './charts/DonutChart';
import { Box, Grid, Typography } from '@mui/material';
import { useErrors } from '../../hooks/useErrors';
import { BLUE, SUBTITLE_TEXT } from '../../theme';
import CustomSelect from '../../components/CustomSelect';
import LoadingSpinner from '../../components/LoadingSpinner';

const CategoryIndicatorsDonutChart = ({ data, indicator }) => {
    const { loading } = useErrors();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [labels, setLabels] = useState([]);
    const initialized = useRef(false); // Controla si ya se realizó la inicialización

    useEffect(() => {
        if (!data.parameters || data.parameters.length === 0) {
            // Si no hay parámetros, vacía las categorías seleccionadas
            setSelectedCategories([]);
            setSeriesData([]);
            setLabels([]);
            return;
        }

        if (!initialized.current) {
            // Seleccionar todas las categorías inicialmente si hay datos
            initialized.current = true;
            setSelectedCategories(data.parameters.map((item) => item.category_name));
        } else {
            updateChartData();
        }
    }, [selectedCategories, data]);

    const updateChartData = () => {
        if (!data.parameters || data.parameters.length === 0 || selectedCategories.length === 0) {
            setSeriesData([]); // Vacía la serie de datos
            setLabels([]);     // Vacía las etiquetas
            return;
        }

        const aggregatedValues = {}; // Objeto para acumular valores por ponderación

        // Sumar los valores de las categorías seleccionadas
        selectedCategories.forEach((categoryName) => {
            const category = data.parameters.find((param) => param.category_name === categoryName);
            if (category) {
                Object.entries(category.values).forEach(([ponderation, value]) => {
                    if (!aggregatedValues[ponderation]) {
                        aggregatedValues[ponderation] = 0;
                    }
                    aggregatedValues[ponderation] += value[indicator] || 0;
                });
            }
        });

        // Ordenar `labels` y `seriesData` según `used_ponderations`
        const orderedPonderations = data.used_ponderations || [];
        const newSeriesData = [];
        const newLabels = [];

        orderedPonderations.forEach((ponderation) => {
            if (aggregatedValues[ponderation] !== undefined) {
                newLabels.push(ponderation);
                newSeriesData.push(aggregatedValues[ponderation]);
            }
        });

        setSeriesData(newSeriesData);
        setLabels(newLabels);
    };

    return (
        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container spacing={2} sx={{ mt: '30px' }}>
                <Grid item xs={11} sm={11} md={9} lg={8} xl={7}>
                    <Typography color={BLUE} sx={SUBTITLE_TEXT}>
                        Ponderación de {indicator}
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={5}>
                    <CustomSelect
                        title="Categorías:"
                        selectItems={data.parameters ? data.parameters.map((param) => param.category_name) : []}
                        value={selectedCategories}
                        onChange={(event) => setSelectedCategories(event.target.value)}
                        horizontal
                        multiple
                        maxWidth={'100%'}
                    />
                </Grid>
            </Grid>
            {loading.getCdMCreditRiskCategoriesPonderations || loading.getLastExecution ? (
                <LoadingSpinner />
            ) : seriesData.length === 0 && labels.length === 0 ? (
                <Typography sx={{ my: '50px', fontWeight: "bold" }}>No hay datos disponibles</Typography>
            ) : (
                <DonutChart seriesData={seriesData} labels={labels} />
            )}
        </Box>
    );
};


export default CategoryIndicatorsDonutChart;
