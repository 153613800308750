import { React } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { StyledDatePicker} from '../../components/CustomStyles';
import CustomSelect from '../../components/CustomSelect';
import { SELECT_TEXT } from '../../theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useErrors } from '../../hooks/useErrors';

export default function SettingsMenu ({ filters, selectedExecution, usersList, executionsList, executionsTooltips, changeFilter, handleOnChangeSelectedExecution, fullDates, normativeVersionsList }) {
    const { loading } = useErrors();
    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                md={2}
            >
                <Typography sx={{ ...SELECT_TEXT, whiteSpace: 'nowrap' }}>
                    Fecha de datos
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ mt: '6px' }}
                        views={['year', 'month']} 
                        name={"dataDate"}
                        value={fullDates.dateDataStart} 
                        onChange={(event) =>{changeFilter("DataStart", event)}}
                        closeOnSelect={true}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            
            <Grid
                item
                md={2}
            >
                <Typography sx={{ ...SELECT_TEXT, whiteSpace: 'nowrap'}}>
                    Fecha de ejecución
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ mt: '6px' }}
                        views={['year', 'month']} 
                        name={"executionDate"}
                        value={fullDates.dateExecutionStart} 
                        onChange={(event) => changeFilter("ExecutionStart", event)}
                        closeOnSelect={true}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid
                item
                md={2}
            >
                <Typography sx={{ ...SELECT_TEXT, whiteSpace: 'nowrap' }}>
                    Versión normativa
                </Typography>
                <CustomSelect
                    selectItems={normativeVersionsList}
                    name={"normativeVersion"}
                    value={filters.normativeVersion} 
                    onChange={(event) => changeFilter("normativeVersion", event.target.value)}
                />
            </Grid>
            <Grid
                item
                md={3}
            >
                <CustomSelect
                    title={'Usuario'}
                    name={"userName"}
                    selectItems={usersList.map(user => user.userName)}
                    value={filters.userName}
                    onChange={(event) => changeFilter("userName", event.target.value)}
                    loading={ loading.getUsers }
                    multiple
                />
            </Grid>
            <Grid
                item
                md={3}
            >
                <CustomSelect
                    title={'Ejecución'}
                    name={"execution"}
                    selectItems={executionsList}
                    tooltipItems={executionsTooltips}
                    value={selectedExecution}
                    onChange={(event) => handleOnChangeSelectedExecution(event.target.value)}
                    loading={ loading.getLastExecution || loading.getProcesses }
                />
            </Grid>
        </Grid>
    );
}
