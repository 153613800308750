import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function configOperationalRiskService() {
    const { apiCall } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const getConfigOperationalRisk = async () => {
        const key = 'getConfigOperationalRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/config/process/operational-risk");
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error encontrando la configuración de procesos de riesgo operacional.");
                Sentry.captureException(response, {
                    tags: { service: 'configOperationalRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando la configuración de procesos de riesgo operacional.");
            Sentry.captureException(error, {
                tags: { service: 'configOperationalRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const putConfigOperationalRisk = async (payload) => {
        const key = 'putConfigOperationalRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/config/process/operational-risk");
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo operacional.");
                Sentry.captureException(response, {
                    tags: { service: 'configOperationalRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo operacional.");
            Sentry.captureException(error, {
                tags: { service: 'configOperationalRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getConfigOperationalRisk, putConfigOperationalRisk };
}
