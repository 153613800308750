import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Collapse, Typography, Stack, Grid } from '@mui/material';
import { PlayCircle, ArrowCircleLeft } from '@mui/icons-material';
import { StyledButton } from '../../../components/CustomStyles';
import { PROCESSES_PATH } from '../../../config/Paths';
import FilesSummaryTable from '../tables/FilesSummaryTable';
import { BLUE, CONTENT_BOX, SUBTITLE_TEXT, SELECT_TEXT } from '../../../theme';
import { executionSummaryService } from '../../../services/executionSummaryService';
import { executionProcessService } from '../../../services/executionProcessService';

const changeMonthStructure = (month) => {
    var changedMonth = String(month + 1);
    if (changedMonth.length === 1) {
        changedMonth = '0' + changedMonth;
    }
    return changedMonth;
}

export default function FinalStep(props) {
    const { activeStep, numSteps, onClickBack, selectFilters, checks, selectedEntryFiles } = props;
    const navigate = useNavigate();
    const { postExecutionSummaryCreditCounterpartyRisk, postExecutionSummaryMarketRisk, postExecutionSummaryOperationalRisk } = executionSummaryService();
    const { postExecutionProcessCreditCounterpartyRisk, postExecutionProcessMarketRisk, postExecutionProcessOperationalRisk } = executionProcessService();
    const [summaryData, setSummaryData] = useState({ data_date: "", user: "", process_type: "", version: "", input_files: [] });

    useEffect(() => {
        const currentStep = numSteps-1;
        if (activeStep === currentStep) {
            setSummaryData({ data_date: "", user: "", process_type: "", version: "", input_files: [] });
            getSummary()
        }
    }, [activeStep]);

    const handleOnClickEnd = () => {
        navigate(PROCESSES_PATH);
    }

    const getSummary = async () => {
        const params = {
            processType: selectFilters.process,
            runVersion: selectFilters.ebaNormative.replace('Reporting ', '').replace(' ', ''),
            month: changeMonthStructure(selectFilters.fullDataDate.$M), 
            year: selectFilters.fullDataDate.$y,
        };
        for (const [key, value] of Object.entries(checks)) {
            params[key] = value ? 'True' : 'False';
        }
        const payload = {specific_files: selectedEntryFiles}
        let data = null;
        // Selección de la función de API según el tipo de proceso
        switch (selectFilters.process) {
            case 'Riesgo de Crédito y Contraparte':
                data = await postExecutionSummaryCreditCounterpartyRisk({params, payload});
                break;
            case 'Riesgo de Mercado':
                data = await postExecutionSummaryMarketRisk({params, payload});
                break;
            case 'Riesgo Operacional':
                data = await postExecutionSummaryOperationalRisk({params, payload});
                break;
            default:
                console.error('Tipo de proceso no reconocido');
                break;
        }
        if (data) {
            setSummaryData(data);
        }
    };

    const executeProcess = async () => {
        const params = {
            runVersion: selectFilters.ebaNormative.replace('Reporting ', '').replace(' ', ''),
            month: changeMonthStructure(selectFilters.fullDataDate.$M), 
            year: selectFilters.fullDataDate.$y,
        };
        for (const [key, value] of Object.entries(checks)) {
            params[key] = value ? 'True' : 'False';
        }
        const payload = {specific_files: selectedEntryFiles}

        // Selección de la función de API según el tipo de proceso
        switch (selectFilters.process) {
            case 'Riesgo de Crédito y Contraparte':
                await postExecutionProcessCreditCounterpartyRisk({params, payload});
                break;
            case 'Riesgo de Mercado':
                await postExecutionProcessMarketRisk({params, payload});
                break;
            case 'Riesgo Operacional':
                await postExecutionProcessOperationalRisk({params, payload});
                break;
            default:
                console.error('Tipo de proceso no reconocido');
                break;
        }
    }

    const checkNonExistingFiles = () => {
        return summaryData.input_files.some(file => file.hasOwnProperty('error'));
    }


    return (
        <Collapse in={activeStep === numSteps-1}>
            <Box sx={{...CONTENT_BOX, mt: '0px', pb: '10px', pt: '20px' }}> 
                <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
                >
                    Tipo de proceso
                </Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: '0px', mb: '15px'}}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <Typography sx={{ ...SELECT_TEXT }}>
                            Proceso:
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }} >
                            {summaryData.process_type}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <Typography sx={{ ...SELECT_TEXT }}>
                            Versión EBA y Normativa:
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }} >
                            {summaryData.version}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <Typography sx={{ ...SELECT_TEXT }} >
                            Fecha de datos:
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                            {summaryData.data_date}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{...CONTENT_BOX, mt: '0px', pb: '10px', pt: '20px' }}> 
                <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
                >
                    Ficheros de entrada
                </Typography>
                <FilesSummaryTable inputFiles={summaryData.input_files} />
                <Box sx={{ pt: '26px', mb: '15px' }}>
                    <Stack
                        direction="row"
                        justifyContent='space-between'
                    >
                        <StyledButton
                            onClick={onClickBack}
                            variant='contained'
                            startIcon={<ArrowCircleLeft />}
                        >
                            Anterior
                        </StyledButton>
                        <StyledButton
                            onClick={() => {executeProcess();handleOnClickEnd();}}
                            variant='contained'
                            endIcon={<PlayCircle />}
                            disabled={checkNonExistingFiles()}
                        >
                            Ejecutar
                        </StyledButton>
                    </Stack>
                </Box>
            </Box>
        </Collapse>
    );
}