import { Paper, Table, TableBody, TableRow,
    TableContainer, TableHead } from '@mui/material';
import {InformesTableCell, InformesTableRow} from '../../../components/CustomStyles';
import { DARK_GREY, GREY } from '../../../theme';
import { styled } from '@mui/material/styles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useEffect, useState } from 'react';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useErrors } from '../../../hooks/useErrors';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { reportsOperationalRiskService } from '../../../services/reportsOperationalRiskService';


const StyledResultTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: 'solid 2px' + DARK_GREY,
    },
    '& .MuiTableCell-root': {
        backgroundColor: GREY
    },
}));


const NormalRow = ({ row }) => {
    const formatNumber = useNumberFormatter();
    return (
        <>
            {/* Main table entry */}
            <InformesTableRow >
                <InformesTableCell>{row.name}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.value)}</InformesTableCell>
            </InformesTableRow>
        </>
    );
}


const TotalRow = ({ row }) => {
    const formatNumber = useNumberFormatter();
    return (
        <>
            {/* Main table entry */}
            <StyledResultTableRow>
                <InformesTableCell><b>{row.name}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.value)}</b></InformesTableCell>
            </StyledResultTableRow>
        </>
    );
}


export default function AggregatedTable({executionYear, executionMonth, processId, initialData, isFirstRender}) {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getOperationalRiskReportAggregated } = reportsOperationalRiskService();
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        if (processId && !isFirstRender.current) {
            fetchReportData();
        }
    }, [processId]);

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) {
            setReportData(initialData);
        }
    }, [initialData]);

    const fetchReportData = async () => {
        const auxReportData = await getOperationalRiskReportAggregated({ year: executionYear, month: executionMonth, process_id: processId });
        if (auxReportData) {
            setReportData(auxReportData)
        }
    }

    if (loading.getOperationalRiskReportAggregated || loading.getLastExecution) {
        return (
            <LoadingSpinner/>
        )
    }
    else if (inLineErrors.getOperationalRiskReportAggregated) {
        return (
            <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getOperationalRiskReportAggregated} />
        )
    }
    else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowXlScreen ? 'auto' : 'hidden' }}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse' }} >
                    <TableHead>
                        <InformesTableRow>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"100%"} colSpan={2}>COMPONENTE DEL INDICADOR DE ACTIVIDAD (BIC)</InformesTableCell>
                        </InformesTableRow>
                    </TableHead>
                    {reportData.length === 0 ?
                        <TableBody>
                            <InformesTableRow>
                                <InformesTableCell colSpan={11} sx={{ textAlign: 'center' }}>No hay datos para mostrar</InformesTableCell>
                            </InformesTableRow>
                        </TableBody>
                        : 
                        <TableBody>
                            {["vl_ic", "vl_am", "vl_dc"].map((key) => (
                                <NormalRow key={key} row={reportData[key]} />
                            ))}
                            <TotalRow row={reportData["vl_ildc"]} />
                            <InformesTableRow><InformesTableCell colSpan={2}> </InformesTableCell></InformesTableRow>
                            {["vl_oi", "vl_oe", "vl_fi", "vl_fe"].map((key) => (
                                <NormalRow key={key} row={reportData[key]} />
                            ))}
                            <TotalRow row={reportData["vl_sc"]} />
                            <InformesTableRow><InformesTableCell colSpan={2}> </InformesTableCell></InformesTableRow>
                            {["vl_tc", "vl_bc"].map((key) => (
                                <NormalRow key={key} row={reportData[key]} />
                            ))}
                            <TotalRow row={reportData["vl_fc"]} />
                            <InformesTableRow><InformesTableCell colSpan={2}> </InformesTableCell></InformesTableRow>
                            <TotalRow row={reportData["vl_bi"]} />
                            <InformesTableRow><InformesTableCell colSpan={2}> </InformesTableCell></InformesTableRow>
                            <TotalRow row={reportData["vl_bic"]} />
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        );
    }
}