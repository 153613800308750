import { Button, TableCell, TableRow, Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, GREY, DARK_GREY } from '../theme';
import { DatePicker } from '@mui/x-date-pickers';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: 'solid 1px' + BLUE,

    },
    '&.MuiTableCell-body': {
        pr: '11px',
        fontWeight: 400,
        borderBottom: 'none'
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root': {
        backgroundColor: GREY
    },
    '&.MuiTableRow-head': {
        fontWeight: 700,
        backgroundColor: 'white'
    },
}));

export const InformesTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0,
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: 'solid 1px' + BLUE,
    },
    '&.MuiTableCell-body': {
        padding: '4px',
    },
}));

export const InformesTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: 'solid 2px' + DARK_GREY
    },
}));

export const InformesResultTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        fontSize: '13px',
        border: `1px solid grey`, // Grey horizontal borders for data cells
        backgroundColor: GREY
    },
}));

export const InformesPaginationTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        fontSize: '13px',
        borderBottom: 'none'
    }
}));

export const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:hover': {
      color: BLUE
    },
    '&.Mui-selected': {
      color: BLUE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    backgroundColor: 'white',
    padding: '0px 15px 9px 15px',
    margin: '0px 5px 0px 5px',
    fontSize: '14px',
}));

export const AntDisabledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&.Mui-selected': {
      color: '#000000',
    },
    backgroundColor: 'white',
    paddingLeft: '30px',
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '9px'
}));

export const AntDisabledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: '#000000',
    },
  });

export const StyledButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        fontSize: '13px',
        textTransform: 'none'
    }
}));

export const StyledBox = styled(Box)({
    color: DARK_GREY,
    backgroundColor: GREY,
    border: 'dashed 2px' + DARK_GREY,
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '30px',
    padding: '30px',
});


export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& input': { 
        height: '0.1px',
        fontSize: '14px', 
        fontWeight: 400,
        backgroundColor: 'white',
    },
    '& MuiIconButton': {
        color: GREY,
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': { 
        backgroundColor: 'white',
        width: '100%', // Asegura que ocupe el ancho total del contenedor
    },
    width: '100%', // Asegura que el componente principal también ocupe todo el ancho
}));
