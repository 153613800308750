import { useEffect, useState } from "react";
import { configEntitiesService } from "../services/configEnititiesService";


export function useEntitiesConfig() {
    const [entities, setEntities] = useState([]);
    const [parameters, setParameters] = useState({});
    const { getConfigEntities, putConfigEntities } = configEntitiesService();


    useEffect(() => {
        fetchEntities();
    }, []);

    const fetchEntities = async () => {
        const data = await getConfigEntities();
        if (data) {
            setEntities(data.current_values);
            setParameters(data.parameters);
        }
    };

    const updateEntities = async (updatedEntities) => {
        updatedEntities.sort((a, b) => parseInt(a.id_entidad_contable) - parseInt(b.id_entidad_contable));
        const data = await putConfigEntities(updatedEntities);
        if (data) {
            setEntities(updatedEntities);
        }
    };

    return { entities, parameters, fetchEntities, updateEntities };
}
