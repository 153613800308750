import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material/';
import CustomSelect from '../../components/CustomSelect';
import ExpandIcon from '../../components/ExpandIcon';
import { GREY } from '../../theme';
import { useProcessesConfig } from '../../hooks/useProcessesConfig';
import { useProcessNames } from '../../hooks/useProcessNames';
import DropdownConfigDialog from './DropdownConfigDialog'
import AmountsSelectionDialog from './AmountsSelectionDialog';
import RelationEpigraphsComponentDialog from './RelationEpigraphsComponentDialog';
import { useErrors } from '../../hooks/useErrors';
import LoadingSpinner from '../../components/LoadingSpinner';


const AccordionEntry = (props) => {
    const { subSectionName, infoContent, dialogDescription, dialogParameters, dialogType } = props;
    const [openInfo, setOpenInfo] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);

    const handleOnClickInfo = () => {
        setOpenInfo(!openInfo);
    };

    const toggleConfig = () => {
        setOpenConfig(!openConfig);
    };

    return (
        <Accordion
            disableGutters
            expanded={openInfo}
            square={true}
            elevation={2}
        >
            <AccordionSummary sx={{ pointerEvents: 'none', backgroundColor: openInfo ? GREY : 'white', height: '10px' }} >
                <Stack
                    direction="row"
                    justifyContent='space-between'
                    sx={{ width: 1 }}
                >
                    <Typography sx={{ mt: '8px', fontSize: '14px', fontWeight: 400 }}>
                        {subSectionName}
                    </Typography>
                    <Box>
                        <>
                            <IconButton
                                sx={{ pointerEvents: 'auto' }}
                                onClick={toggleConfig}
                            >
                                <Settings />
                            </IconButton>
                            {dialogType === "Dropdown" &&
                                <DropdownConfigDialog
                                    subSectionName={subSectionName}
                                    open={openConfig}
                                    toggleConfig={toggleConfig}
                                    dialogDescription={dialogDescription}
                                    dialogParameters={dialogParameters}
                                />
                            }
                            {dialogType === "Table" && subSectionName === "Relación epígrafes importe" &&
                                <AmountsSelectionDialog
                                    subSectionName={subSectionName}
                                    open={openConfig}
                                    toggleConfig={toggleConfig}
                                    dialogDescription={dialogDescription}
                                    dialogParameters={dialogParameters}
                                />
                            }
                            {dialogType === "Table" && subSectionName === "Relación epígrafes componente" &&
                                <RelationEpigraphsComponentDialog
                                    subSectionName={subSectionName}
                                    open={openConfig}
                                    toggleConfig={toggleConfig}
                                    dialogDescription={dialogDescription}
                                    dialogParameters={dialogParameters}
                                />
                            }
                        </>
                        <ExpandIcon
                            size={'medium'}
                            open={openInfo}
                            onClick={handleOnClickInfo}
                        />
                    </Box>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '30px' }}>
                {infoContent}
            </AccordionDetails>
        </Accordion>
    );
}





export default function ProcessesConfig() {
    const { loading } = useErrors();
    const { processNamesList } = useProcessNames({fetchConfigurable: true});
    const [selectedProcessName, setSelectedProcessName] = useState('');
    const {configSubSections, fetchCreditRiskProcessesConfig, fetchOperationalRiskProcessesConfig} = useProcessesConfig();

    useEffect(() => {
        switch(selectedProcessName) {
            case "Riesgo de Crédito y Contraparte":
                fetchCreditRiskProcessesConfig();
            case "Riesgo de Mercado":
                break;
            case "Riesgo Operacional":
                fetchOperationalRiskProcessesConfig();
                break;
        }
    }, [selectedProcessName]);


    const handleOnChangeProcess = (event) => {
        setSelectedProcessName(event.target.value);
    };

    const showSubSections = () => {
        return Object.entries(configSubSections).length > 0;
    }

    return (
        <Box sx={{ mt: '0px', pb: '10px', pt: '10px' }}> 
            <Grid
                container
                sx={{ mb: '15px' }}
            >
                <Grid
                    item
                    md={4}
                >
                    <CustomSelect
                        title={'Proceso'}
                        selectItems={processNamesList}
                        value={selectedProcessName}
                        onChange={handleOnChangeProcess}
                    />
                </Grid>
            </Grid>
            {loading.getConfigCreditRisk || loading.getConfigOperationalRisk ? (
                <LoadingSpinner />
            ) : (
                <Collapse in={showSubSections()}>
                    {Object.entries(configSubSections).map(([subSectionName, subSectionData]) => (
                        <AccordionEntry
                            key={subSectionName}   
                            subSectionName={subSectionName}
                            infoContent={subSectionData["description"]}
                            dialogDescription={subSectionData["desc_dialog"]}
                            dialogParameters={subSectionData["parameters"]}
                            dialogType={subSectionData["type"]}
                        />
                    ))}
                </Collapse>
            )}
            
        </Box>
    );
}