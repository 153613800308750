import { useState, useEffect } from 'react';
import { parametrizationService } from '../services/parametrizationService';

export function useProcessNames({ fetchConfigurable = false } = {}) {
    const { getParametrization } = parametrizationService();
    const [processNamesList, setProcessNamesList] = useState([]);

    useEffect(() => {
        if (fetchConfigurable) {
            fetchConfigurableProcessNames();
        } else {
            fetchProcessNames();
        }
    }, [fetchConfigurable]);

    const fetchProcessNames = async () => {
        const data = await getParametrization({ parameterCode: 'processes' });
        if (data) {
            const namesList = data.processes.map(value => value.name);
            setProcessNamesList(namesList);
        }
    };

    const fetchConfigurableProcessNames = async () => {
        const data = await getParametrization({ parameterCode: 'processes' });
        if (data) {
            const namesList = data.processes
                .filter(value => value.configurable)
                .map(value => value.name);
            setProcessNamesList(namesList);
        }
    };

    return { processNamesList };
}
