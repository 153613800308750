import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function configEntitiesService() {
    const { apiCall } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError, setDialogErrorState, clearDialogError } = useErrors();

    const getConfigEntities = async () => {
        const key = 'getEntities';
        const fileUrl = new URL(window.REACT_APP_API_URL + "/config/entities");
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando las entidades.");
                Sentry.captureException(response, {
                    tags: { service: 'entitiesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando las enidades.");
            Sentry.captureException(error, {
                tags: { service: 'entitiesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const putConfigEntities = async (updatedEntities) => {
        const key = 'putEntities';
        const fileUrl = new URL(window.REACT_APP_API_URL + '/config/entities');
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl, updatedEntities);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar las entidades con las modificaciones.");
                Sentry.captureException(response, {
                    tags: { service: 'entitiesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar las entidades con las modificaciones.");
            Sentry.captureException(error, {
                tags: { service: 'entitiesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getConfigEntities, putConfigEntities };
}
